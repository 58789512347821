$(function () {
  $('.slick-service').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('.service-wrap .prev'),
    nextArrow: $('.service-wrap .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  $('.slide-about').slick({
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });


  /*==============================*/
  $('#luxurysedansS').slick({
    dots: true,
    lazyLoad: 'ondemand',
    appendDots: $('#d-01'),
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('#luxurysedans .prev'),
    nextArrow: $('#luxurysedans .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  $('#sportutilityS').slick({
    dots: true,
    lazyLoad: 'ondemand',
    appendDots: $('#d-02'),
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('#sportutility .prev'),
    nextArrow: $('#sportutility .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  $('#luxuryvansS').slick({
    dots: true,
    lazyLoad: 'ondemand',
    appendDots: $('#d-03'),
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('#luxuryvans .prev'),
    nextArrow: $('#luxuryvans .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  $('#luxurylimousinesS').slick({
    dots: true,
    lazyLoad: 'ondemand',
    appendDots: $('#d-04'),
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('#luxurylimousines .prev'),
    nextArrow: $('#luxurylimousines .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });
  /*==============================*/

  $('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
    $('#luxurylimousinesS, #luxuryvansS, #sportutilityS, #luxurysedansS').each(function() {
      $(this).slick("getSlick").refresh();
    });
  })

})
